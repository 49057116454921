import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './mobileNavbar.css';
import SearchBox from '../SearchBox/searchBox';
import recyclingLogo from '../../media/images/recycling-logo.png';
import greenLoopsLogo from '../../media/images/green-loops-logo.png'; // Import the official logo
import searchIcon from '../../media/images/search-icon.png'; // Import the search icon
import { useCart } from '../../contexts/cartContext'; // Import useCart hook

function MobileNavbar() {
  const { getTotalQuantity } = useCart(); // Get cartItemCount from context
  const [searchVisible, setSearchVisible] = useState(false);
  const searchInputRef = useRef(null); // Ref to access the search input

  const toggleSearchScreen = () => {
    setSearchVisible(!searchVisible);
  };

  const handleClickOutside = (e) => {
    // If the user clicks outside the search box, close the overlay
    if (e.target.classList.contains('search-overlay')) {
      setSearchVisible(false);
    }
  };

  // Callback to close the search popup when a search is submitted
  const handleSearchSubmit = () => {
    setSearchVisible(false);
  };

  // Focus the search input when the search screen becomes visible
  React.useEffect(() => {
    if (searchVisible && searchInputRef.current) {
      searchInputRef.current.focus(); // Focus the input
    }
  }, [searchVisible]);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-title-link">
            <img src={greenLoopsLogo} alt="Green Loops Instantly Sell Your Used Books" className="navbar-logo" />
          </Link>

          <div className="navbar-links">
            <img
              src={searchIcon}
              alt="Search Icon"
              className="search-icon"
              onClick={toggleSearchScreen}
            />
            <Link to="/cart" className="navbar-cart">
              <img src={recyclingLogo} alt="Recycling Logo" className="navbar-cart-logo" />
              <div className="navbar-cart-count-container">
                <span className="navbar-cart-count">{getTotalQuantity()}</span>
              </div>
            </Link>
          </div>
        </div>
      </nav>

      {/* Search Screen Overlay */}
      {searchVisible && (
        <div className="search-overlay" onClick={handleClickOutside}>
          <div className="search-screen">
            <SearchBox
              onSearchSubmit={handleSearchSubmit}
              searchInputRef={searchInputRef} // Pass the ref to the search box
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MobileNavbar;
