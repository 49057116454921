import React from 'react';
import './navbar.css';
import NavbarDesktop from '../DesktopNavbar/desktopNavbar';
import MobileNavbar from '../MobileNavbar/mobileNavbar';
import useScreenSize from '../../hooks/useScreenSize'; // Custom hook for screen size detection

function Navbar() {
  const isMobile = useScreenSize();

  return isMobile ? <MobileNavbar /> : <NavbarDesktop />;
}

export default Navbar;
