// src/components/BookPage/bookPage.js
import React, { useState } from 'react';
import { useCart } from '../../contexts/cartContext'; // Import useCart hook
import ReactGA from 'react-ga4';
import './bookPage.css';

function BookPage({ book, onClose }) {
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart(); // Get addToCart function from context

  const increaseQuantity = () => setQuantity(Math.min(quantity + 1,10));
  const decreaseQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleAddToCart = () => {
    addToCart(book, quantity); // Add book with quantity to cart

    // Track "Add to Cart" event in Google Analytics
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Add to Cart',
      label: book.item_name,  // Book title
      value: book.price * quantity, // Total price based on quantity
      items: [{
        item_name: book.item_name,
        quantity: quantity,
        price: book.price,
      }]
    });

    onClose(); // Close the pop-up after adding
  };

  return (
    <div className='book-page-overlay' onClick={handleOverlayClick}>
      <div className='book-page-container'>
        <img src={book.image || 'default-image-url'} alt={book.item_name || 'No title'} className='book-page-image' />
        <div className='book-page-content'>
          <h2 className='book-page-title'>{book.item_name || 'No title'}</h2>
          <p className='book-page-author'>By: {book.author || 'N/A'}</p>
          <div className='book-page-details'>
            <div className='book-page-detail'>
              <h3>Book Details</h3>
              <p><span className='detail-label'>ISBN:</span> {book.externally_assigned_product_identifier || 'N/A'}</p>
              <p><span className='detail-label'>Binding:</span> {book.binding || 'N/A'}</p>
              <p><span className='detail-label'>Language:</span> {book.language || 'N/A'}</p>
              <p><span className='detail-label'>Weight:</span> {book.item_weight ? `${book.item_weight} ${book.item_weight_unit}` : 'N/A'}</p>
              <p><span className='detail-label'>Dimensions:</span> {book.width && book.length && book.height 
                ? `${book.width} ${book.width_unit} x ${book.length} ${book.length_unit} x ${book.height} ${book.height_unit}` 
                : 'N/A'}</p>
            </div>
            <div className='book-page-detail'>
              <h3>Series and Publisher</h3>
              <p><span className='detail-label'>Series Title:</span> {book.series_title || 'N/A'}</p>
              <p><span className='detail-label'>Publisher:</span> {book.manufacturer || 'N/A'}</p>
            </div>
          </div>
          <p className='book-page-price'>Sell for: ${book.price.toFixed(2)}</p>
          <div className='quantity-and-cart'>
            <div className='quantity-selector'>
              <button className='quantity-button' onClick={decreaseQuantity}>-</button>
              <span className='quantity-display'>{quantity}</span>
              <button className='quantity-button' onClick={increaseQuantity}>+</button>
              <button className='add-to-cart-button' onClick={handleAddToCart}>Add to Cart</button>
            </div>
          </div>
        </div>
        <button onClick={onClose} className='book-page-close'>×</button>
      </div>
    </div>
  );
}

export default BookPage;