import React, { useState, useEffect, useRef } from 'react';
import { TextField, CircularProgress, InputAdornment, Popper, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DropdownItem from '../DropdownItem/dropdownItem';
import './searchBox.css';
import searchIcon from '../../media/images/search-icon.png'; // Import the search icon
import ReactGA from 'react-ga4';

function SearchBox(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detect mobile
  const searchBoxRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Update mobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (option) => {
    setAnchorEl(null);
    if (inputValue.trim()) {
      navigate(`/search/${encodeURIComponent(inputValue.trim())}/${option.id}`);
    }
    if (isMobile && props.onSearchSubmit) {
      props.onSearchSubmit(); // Close the popup on mobile
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (inputValue.trim()) {
      // Send search event to Google Analytics
      ReactGA.event({
        category: 'Search',
        action: 'Search Query',
        label: inputValue.trim(), // Track the search query
      });
      
      navigate(`/search/${encodeURIComponent(inputValue.trim())}`);
    }
    setAnchorEl(null);
  
    if (isMobile) {
      searchBoxRef.current.blur();
    }
  
    if (props.onSearchSubmit) {
      props.onSearchSubmit();
    }
  };  

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length >= 2) {
        fetchBooks(inputValue);
      } else {
        setOptions([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const fetchBooks = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.greenloops.org/search?query=${encodeURIComponent(query)}&limit=10`
      );
      const data = await response.json();
      const mappedOptions = data.map((result) => ({
        label: result.item_name,
        id: result._id,
        image: result.image,
        author: result.author,
      }));
      setOptions(mappedOptions);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <form onSubmit={handleSearchSubmit} className="search-box-container" ref={searchBoxRef}>
      <TextField
        id="search-input"
        className="search-box"
        placeholder="Search book"
        value={inputValue}
        onChange={handleInputChange}
        inputRef={props.searchInputRef} // Set the ref here
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              <img
                src={searchIcon}
                alt="Search Icon"
                className="search-icon"
                onClick={(event) => {
                  event.preventDefault();
                  handleSearchSubmit(event);
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={Boolean(anchorEl) && options.length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
        className="search-box-dropdown"
        ref={dropdownRef}
        style={{ width: searchBoxRef.current ? searchBoxRef.current.clientWidth : undefined }} // Ensure dropdown width matches search box
      >
        <Paper className="search-box-dropdown-inner">
          {options.map((option) => (
            <DropdownItem key={option.id} option={option} onClick={handleOptionClick} />
          ))}
        </Paper>
      </Popper>
    </form>
  );
}

export default SearchBox;
