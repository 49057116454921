// src/components/CalendlyWidget/CalendlyWidget.js
import React, { useEffect } from 'react';

const CalendlyWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/greenloopinc/book-pickup?hide_gdpr_banner=1&background_color=ffffff&text_color=1a1a1a&primary_color=34c759"
            style={{ minWidth: '320px', height: '700px' }}
        ></div>
    );
};

export default CalendlyWidget;
