import React from 'react';
import { MenuItem, Avatar, Typography } from '@mui/material';
import './dropdownItem.css';

function DropdownItem({ option, onClick }) {
  return (
    <div className="dropdown-box">
      <MenuItem className="dropdown-item" onClick={() => onClick(option)}>
        <Avatar
          src={option.image}
          alt={option.label}
          className="dropdown-item-avatar"
          variant="square"
          sx={{
            width: 60,
            height: 90,
            objectFit: "cover", // changed to cover for a better image fit
            borderRadius: "5px", // increased border radius for a softer look
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }}
        />
        <div className="dropdown-item-text">
          <Typography variant="body1" color="#333" fontWeight="bold">
            {option.label}
          </Typography>
          <Typography variant="body2" color="#757575">
            {option.author}
          </Typography>
        </div>
      </MenuItem>
    </div>
  );
}

export default DropdownItem;