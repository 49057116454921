import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../contexts/cartContext';
import './cartPage.css';
import ReactGA from 'react-ga4';

const CartPage = () => {
  const { cart, removeFromCart, increaseQuantity, decreaseQuantity } = useCart();

  // Ensure the page scrolls to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({ hitType: "pageview", page: "/cart" });
    // Track cart details
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Cart Page View',
      label: 'Cart Details',
      items: cart.map(item => ({
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.price
      })),
      value: getTotalPrice(),
    });
  }, []);

  const handleRemoveClick = (asin) => {
    removeFromCart(asin);
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  return (
    <div className='cart-page'>
      <h1 className='cart-title'>Your Cart</h1>
      <div className='cart-items'>
        {cart.length === 0 ? (
          <p className='empty-cart'>Your cart is empty. Please add some items before proceeding to checkout.</p>
        ) : (
          cart.map(item => (
            <div key={item.asin} className='cart-item'>
              <img src={item.image} alt={item.item_name} className='cart-item-image' />
              <div className='cart-item-details'>
                <h2 className='cart-item-title'>{item.item_name}</h2>
                <p className='cart-item-price'>${item.price.toFixed(2)}</p>
                <div className='quantity-controls'>
                  <button className='quantity-button' onClick={() => decreaseQuantity(item.asin)}>-</button>
                  <span className='quantity'>{item.quantity}</span>
                  <button className='quantity-button' onClick={() => increaseQuantity(item.asin)}>+</button>
                  <button className='remove-button' onClick={() => handleRemoveClick(item.asin)}>
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="cart-total">
        <span className="cart-total-price"><span className="cart-total-label">Total Book Value: </span> ${getTotalPrice().toFixed(2)}</span>
        {cart.length > 0 ? (
          <Link to="/checkout">
            <button className="checkout-button">Proceed to Checkout</button>
          </Link>
        ) : (
          <button className="checkout-button" disabled>Proceed to Checkout</button>
        )}
      </div>
    </div>
  );
};

export default CartPage;
