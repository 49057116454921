import React from 'react';
import './bookItem.css';

function BookItem({ image, price, onClick }) {
  const displayPrice = price != null ? price : 0;

  return (
    <div className='book-frame' onClick={onClick}>
      <div className='book-cover-wrapper'>
        <img className='book-cover' src={image} alt='Book Cover' />
      </div>
      <button className='sell-now-button'>
        <span className='price-label'>Sell for:</span>
        <span className='price-value'>${displayPrice.toFixed(2)}</span>
      </button>
    </div>
  );
}

export default BookItem;