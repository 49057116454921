// src/components/ShopGrid/ShopGrid.js
import React, { useState, useEffect } from 'react';
import BookItem from '../BookItem/bookItem';
import BookPage from '../BookPage/bookPage'; // Import the BookPage component
import ReactGA from 'react-ga4'; // Import react-ga4
import './shopGrid.css';

const ShopGrid = () => {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [genres, setGenres] = useState([
    "Fiction", "Fantasy", "Young Adult Fiction", "Juvenile Fiction",
    "Business & Economics", "Self-Help", "Romance"
  ]);

  const handleBookClick = (book) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Click on Book Item',
      label: `Book ASIN: ${book.asin}`,
    });
    setSelectedBook(book); // Set the selected book when clicked
  };

  // Fetch books from API based on genres
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const allBooks = [];
        for (const genre of genres) {
          const response = await fetch(`https://api.greenloops.org/top-books-genre?genre=${encodeURIComponent(genre)}`);
          const data = await response.json();
          allBooks.push(...data);
        }
        setBooks(allBooks);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();
  }, [genres]);

  // Create a dictionary to organize books by genre
  const booksByGenre = genres.reduce((acc, genre) => {
    acc[genre] = books.filter(book => book.genre === genre);
    return acc;
  }, {});

  return (
    <div className='shop-grid'>
      {genres.map(genre => (
        <div key={genre} className='genre-section'>
          <h2 className='genre-title'>{genre}</h2>
          <div className='genre-books'>
            {booksByGenre[genre]?.map(book => (
              <BookItem 
                key={book.id} 
                {...book} 
                onClick={() => handleBookClick(book)} // Pass the onClick handler
              />
            ))}
          </div>
        </div>
      ))}
      
      {selectedBook && (
        <BookPage 
          book={selectedBook} 
          onClose={() => setSelectedBook(null)} // Add an onClose prop to close the pop-up
        />
      )}
    </div>
  );
}

export default ShopGrid;
