// src/components/ThankYouPage/ThankYouPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './thankYouPage.css';
import ReactGA from 'react-ga4';

const ThankYouPage = () => {
  const [cart, setCart] = useState([]);
  const [error, setError] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const startTime = urlParams.get('event_start_time');
  const endTime = urlParams.get('event_end_time');
  const inviteeName = urlParams.get('invitee_full_name');
  const inviteeEmail = urlParams.get('invitee_email');
  const event_type = "https://api.calendly.com/event_types/" + urlParams.get('event_type_uuid');
  const cancel_url = "https://calendly.com/cancellations/" + urlParams.get('invitee_uuid');
  const phoneNumber = urlParams.get('answer_1'); // Ensure this is included

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/thank-you" }); // Sending page view for CheckoutPage
    // Fetch cart information from local storage or other source
    const fetchCart = () => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      // Only include necessary fields
      const cartDetails = storedCart.map(item => ({
        author: item.author,
        asin: item.asin,
        isbn: item.externally_assigned_product_identifier || 'N/A',
        price: item.price,
        quantity: item.quantity,
        name: item.item_name
      }));
      console.log(cartDetails);
      setCart(cartDetails);
    };
    console.log("HIII");
    fetchCart();
  }, []);

  useEffect(() => {
    const updateOrderWithCart = async () => {
      try {
        await axios.post('https://api.greenloops.org/api/updateOrder', {
          cancel_url: cancel_url,
          event_type: event_type,
          customer_name: inviteeName,
          cart: cart
        });
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred while updating the order.");
      }
    };

    if (cart.length > 0) {
      updateOrderWithCart();
    }
  }, [cart, startTime, endTime, inviteeName, inviteeEmail, phoneNumber]);


  return (
    <div className="thank-you-page">
      <h1>Thank you for booking!</h1>
      <p>Event Details:</p>
      <ul>
        <li><strong>Start Time:</strong> {startTime}</li>
        <li><strong>End Time:</strong> {endTime}</li>
        <li><strong>Name:</strong> {inviteeName}</li>
        <li><strong>Email:</strong> {inviteeEmail}</li>
        <li><strong>Phone Number:</strong> {phoneNumber}</li>
      </ul>

      <h2>Order Summary:</h2>
      <table>
        <thead>
          <tr>
            <th>Book Name</th>
            <th>Author</th>
            <th>ISBN</th>
            <th>Value</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.author}</td>
              <td>{item.isbn}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="order-total">
        <span>Total Value:</span> <span className="price">${cart.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2)}</span>
      </div>
    </div>
  );
};

export default ThankYouPage;