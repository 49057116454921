import React, { useEffect, useState } from 'react';
import ShopGrid from '../ShopGrid/shopGrid';
import landingPageImage from '../../media/images/landing-page.jpg'; // Import the image
import './homeScreen.css'; // Import the CSS file
import ReactGA from 'react-ga4'; // Import ReactGA
import addBooksIcon from '../../media/images/add-books.png'; // Example icon for adding books
import scheduleIcon from '../../media/images/schedule.png'; // Example icon for scheduling
import meetIcon from '../../media/images/meet.png'; // Example icon for meeting and transferring books

const HomeScreen = () => {
  const [startTime] = useState(Date.now()); // Initialize startTime when the component mounts

  useEffect(() => {
    // Track page view only once when the component mounts
    ReactGA.send({ hitType: "pageview", page: "/" });

    // Cleanup function to track time spent on the page
    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // Time spent in seconds

      // Send time spent to GA4
      ReactGA.event({
        category: 'User Engagement',
        action: 'Time Spent on HomeScreen',
        value: timeSpent
      });
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="home-screen">
      <div className="hero-container">
        <img src={landingPageImage} alt="Green Loops Landing Page" className="landing-page-image" />
        <div className="hero-text-overlay">
          <h1>Reduce, Reuse, Read</h1>
          <p>Sell your used books to help reduce waste and give great stories a second life.</p>
        </div>
      </div>

      <div className="instructions-container">
        <div className="instructions-border">
          <h2 className="instructions-heading">How It Works</h2>
          <div className="instructions">
            <div className="instruction-item">
              <div className="instruction-number">1.</div>
              <div className="circle">
                <img src={addBooksIcon} alt="Add Books" className="instruction-icon" />
              </div>
              <p className="instruction-text">Add books you want to sell to your cart</p>
            </div>
            <div className="instruction-item">
              <div className="instruction-number">2.</div>
              <div className="circle">
                <img src={scheduleIcon} alt="Schedule Pickup" className="instruction-icon" />
              </div>
              <p className="instruction-text">Pick a time and place for pickup</p>
            </div>
            <div className="instruction-item">
              <div className="instruction-number">3.</div>
              <div className="circle">
                <img src={meetIcon} alt="Meet and Transfer" className="instruction-icon" />
              </div>
              <p className="instruction-text">Meet up, transfer books, and get paid!</p>
            </div>
          </div>
        </div>
        <p className="footnote">* Pickups Currently only available on/near Georgia Tech Campus *</p>
      </div>

      <ShopGrid />
    </div>
  );
};

export default HomeScreen;
