// src/contexts/CartContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    // Initialize cart state from localStorage, or start with an empty array
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    // Save the cart to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item, quantity) => {
        const existingItem = cart.find(cartItem => cartItem.asin === item.asin);
        if (existingItem) {
            const newQuantity = Math.min((existingItem.quantity || 0) + quantity, 10);
            setCart(prevCart => prevCart.map(cartItem => 
                cartItem.asin === item.asin 
                ? { ...cartItem, quantity: newQuantity } 
                : cartItem
            ));
        } else {
            setCart(prevCart => [...prevCart, { ...item, quantity }]);
        }
    };

    const removeFromCart = (asin) => {
        setCart(prevCart => prevCart.filter(item => item.asin !== asin));
    };

    const increaseQuantity = (asin) => {
        const newCart = cart.map((item) => {
            if (item.asin === asin) {
                return { ...item, quantity: Math.min(item.quantity + 1, 10) };
            }
            return item;
        });
        setCart(newCart);
    };

    const decreaseQuantity = (asin) => {
        setCart(prevCart => prevCart.map(item => 
            item.asin === asin && (item.quantity || 0) > 1
            ? { ...item, quantity: Math.max((item.quantity || 0) - 1, 0) } 
            : item
        ));
    };

    // Function to calculate the total quantity of books in the cart
    const getTotalQuantity = () => {
        return cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, increaseQuantity, decreaseQuantity, getTotalQuantity }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
