import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BookItem from '../BookItem/bookItem';
import BookPage from '../BookPage/bookPage';
import './searchResult.css';
import ReactGA from 'react-ga4';

const SearchResult = () => {
  const { query, asin } = useParams(); // Get the search query and ASIN from the URL
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [page, setPage] = useState(1);
  const [startTime, setStartTime] = useState(null);

  // Effect for tracking page views and fetching data
  useEffect(() => {
    // Set the start time when the effect runs
    setStartTime(Date.now());

    const fetchBooks = async () => {
      try {
        if (query && asin) {
          ReactGA.send({ hitType: "pageview", page: `/search/${query}/${asin}` }); // Dynamic page view tracking
        } else {
          ReactGA.send({ hitType: "pageview", page: `/search/${query}` }); // Track if only query is available
        }
        const response = await fetch(`https://api.greenloops.org/search?query=${encodeURIComponent(query)}&page=${page}&limit=63`);
        const data = await response.json();
        setBooks(data);
        if (asin) {
          const book = await fetch(`https://api.greenloops.org/book?asin=${asin}`);
          const bookData = await book.json();
          setSelectedBook(bookData);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchBooks();
  }, [query, page, asin]); // Runs when query, page, or ASIN changes

  // Effect for tracking time spent on the page
  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(Date.now());

    // Cleanup function to track time spent on the page
    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // Time spent in seconds

      // Send time spent and URL to GA4
      ReactGA.event({
        category: 'User Engagement',
        action: 'Time Spent on SearchResult',
        label: `Search Query: ${query}`,
        value: timeSpent
      });
    };
  }, [query, page, asin]); // Ensures this effect runs when any of these values change

  const handleBookClick = (book) => {
    setSelectedBook(book); // Set the selected book when clicked

    // Track book clicks
    ReactGA.event({
      category: 'User Interaction',
      action: 'Click on Book Item',
      label: `Book ASIN: ${book.asin}`,
    });
  };

  return (
    <div className="search-result">
      <h1 className="search-title">Search Results for: "{query}"</h1>
      <div className="books-grid">
        {books.map(book => (
          <BookItem 
            key={book.id} 
            {...book} 
            onClick={() => handleBookClick(book)} 
          />
        ))}
      </div>

      {selectedBook && (
        <BookPage 
          book={selectedBook} 
          onClose={() => setSelectedBook(null)} // Add an onClose prop to close the pop-up
        />
      )}
    </div>
  );
};

export default SearchResult;
