import React, { useEffect, useState, useMemo } from 'react';
import { useCart } from '../../contexts/cartContext';
import CalendlyWidget from '../CalendlyWidget/calendlyWidget';
import './checkout.css';
import ReactGA from 'react-ga4'; 

const CheckoutPage = () => {
    const { cart, removeFromCart, increaseQuantity, decreaseQuantity } = useCart();
    const [startTime] = useState(Date.now()); // Initialize startTime only once

    useEffect(() => {
        // Track page view
        ReactGA.send({ hitType: "pageview", page: "/checkout" });

        // Track cart details
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Checkout Page View',
            label: 'Cart Details',
            items: cart.map(item => ({
                item_name: item.item_name,
                quantity: item.quantity,
                price: item.price
            })),
            value: getTotalPrice(),
        });

        return () => {
            // Calculate time spent on the page
            const endTime = Date.now();
            const timeSpent = (endTime - startTime) / 1000; // Time spent in seconds

            // Send time spent to GA4
            ReactGA.event({
                category: 'User Engagement',
                action: 'Time Spent on Checkout Page',
                value: timeSpent
            });
        };
    }, []); // Empty dependency array ensures this effect runs only once

    const orderSummary = useMemo(() => {
        return cart.map(item => ({
            name: item.item_name,
            quantity: item.quantity,
            price: item.price
        }));
    }, [cart]);

    const getTotalPrice = () => {
        return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
    };

    return (
        <div className='checkout-page'>
            <div className='order-summary'>
                <h2 className='order-summary-title'>Order Summary</h2>
                {cart.length === 0 ? (
                    <p className='empty-cart'>Your cart is empty.</p>
                ) : (
                    cart.map(item => (
                        <div key={item.asin} className='order-summary-item'>
                            <img src={item.image} alt={item.item_name} className='order-summary-image' />
                            <div className='order-summary-details'>
                                <h3 className='order-summary-item-name'>{item.item_name}</h3>
                                <p className='order-summary-item-price'>
                                    ${item.price.toFixed(2)} x {item.quantity}
                                </p>
                                <div className='quantity-controls'>
                                    <button className='quantity-button' onClick={() => decreaseQuantity(item.asin)}>-</button>
                                    <span className='quantity'>{item.quantity}</span>
                                    <button className='quantity-button' onClick={() => increaseQuantity(item.asin)}>+</button>
                                    <button className='remove-button' onClick={() => removeFromCart(item.asin)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                
                <div className='order-summary-total'>
                    <h3>Total Book Value: <span>${getTotalPrice().toFixed(2)}</span></h3>
                </div>
            </div>

            <div className="calendly-container">
                <h2>Submit Order by Scheduling a Pickup Time</h2>
                <CalendlyWidget orderSummary={orderSummary} totalPrice={getTotalPrice()} />
            </div>
        </div>
    );
};

export default CheckoutPage;
